<template>
  <v-dialog v-model="dialog"
      max-width="800px"  transition="dialog-bottom-transition" scrollable>
    <v-card v-if="cron_log_detail">
      <v-toolbar flat>
        <v-card-title>
          <span>{{cron_log_detail.command}}</span>
          <hr />
        </v-card-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeDialog">
            <i class="fa fa-close"></i>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col md="12" v-if="cron_log_detail.output">
              <h5>Output</h5>
              <pre>
                <div class="pre">
                   <p style="overflow-y: scroll;" class="font-weight-medium"  v-html="cron_log_detail.output"> </p>
                </div>
              </pre>

            </v-col>
            <v-col md="12" v-else>
              <h5>No output</h5>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            text class="cancel-btn"
            x-large
            @click="closeDialog">
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import CronLogService from "@/services/cron-log/CronLogService";
const cronLog=new CronLogService();
  export default {
    data(){
      return{
        dialog:false,
        cron_log_detail:null,
      }
    },
    methods:{
      closeDialog(){
        this.dialog=false;
        this.cron_log_detail=null;
      },
      openDialog(){
        this.dialog=true;
      },
      viewCronLogDetail(logId){
        if(logId){
          this.openDialog();
          this.getCronLogDetail(logId)
        }
      },

      getCronLogDetail(logId){
        cronLog
            .show(logId)
            .then((response) => {
              this.cron_log_detail = response.data.cron_log;
            })
            .catch((err) => {

            });
      }
    }
  }
</script>
<style scoped>
.pre {
  background-color: #f4f4f4;
  padding: 10px;
  border: 1px solid #ddd;
  overflow: auto;
  font-family: 'Courier New', monospace;
  font-size: 14px;
  display: block;
  white-space: pre;
}

.code {

}
</style>
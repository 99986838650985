<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>
                  Cron log
                </h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>\ Cron log
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
              <v-row>
                <v-col cols="12" sm="6" md="3">
                  <v-text-field
                      label="Search by comand"
                      v-model="search.command"
                      outlined
                      dense
                      v-on:keyup.enter="searchCronLog()"
                      @input="search.command = $event !== null ? $event : ''"
                      clearable
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md=3>
                  <v-menu
                      v-model="date_menu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                          v-model="search.run_at"
                          label="Run at"
                          prepend-icon="" prepend-inner-icon="mdi-calendar"
                          readonly dense outlined
                          v-bind="attrs"
                          v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="search.run_at"
                        @input="date_menu = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col class="d-flex" cols="12" sm="6" md="3">
                  <v-select
                      :items="search_statuses"
                      v-model="search.status"
                      label="Status"
                      item-text="name"
                      item-value="value"
                      outlined
                      clearable
                      @input="search.status = $event !== null ? $event : ''"
                      v-on:keyup.enter="searchCronLog()"
                      dense
                  ></v-select>
                </v-col>
                <v-col cols="12" md="12" class="text-right">
                  <v-btn
                      @click="searchCronLog()"
                      class="btn btn-primary btn-search "
                      :loading="loading"
                  >
                    <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                    Search
                  </v-btn>
                </v-col>
              </v-row>
            </div>
            <div class="table-responsive">
              <v-skeleton-loader type="table-thead" v-if="loading">
              </v-skeleton-loader>
              <v-skeleton-loader v-if="loading" type="table-row-divider@25">
              </v-skeleton-loader>
              <table class="table" v-if="!loading">
                <thead>
                <tr class="text-left">
                  <th class="px-3">Title</th>
                  <th class="px-3">Frequency</th>
                  <th class="px-3">Run at</th>
                  <th class="px-3">Status</th>
                  <th class="pr-3 text-center">Options</th>
                </tr>
                </thead>
                <tbody>
                <tr v-show="logs.length > 0" v-for="(item,index) in logs">
                  <td>
                        <span class="font-weight-medium">
                        {{item.command}}
                      </span>
                  </td>
                  <td>
                    <span class="font-weight-medium">
                        {{item.frequency_text || '-'}}
                      </span>
                  </td>

                  <td>
                      <span class="font-weight-medium">
                        {{item.run_at_format}}
                      </span>
                  </td>

                  <td>
                      <span class="badge" v-bind:class="{ 'badge-success': item.status=='success', 'badge-danger': item.status=='error' }">
                        {{item.status_text}}
                      </span>
                  </td>

                  <td class="pr-0 text-center">
                    <template>
                      <b-dropdown size="sm" variant="link" toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                                  no-caret right no-flip>
                        <template v-slot:button-content>
                          <i class="ki ki-bold-more-hor"></i>
                        </template>
                        <!--begin::Navigation-->
                        <div class="navi navi-hover min-w-md-250px">
                          <b-dropdown-text tag="div" class="navi-item" >
                            <a @click="viewCronLogDetail(item.id)" class="navi-link">
                              <span class="navi-icon">
                                <i class="fas fa-eye"></i>
                              </span>
                              <span class="navi-text">View detail</span>
                            </a>
                          </b-dropdown-text>
                        </div>
                      </b-dropdown>
                    </template>
                  </td>
                </tr>
                <tr v-if="logs.length == 0">
                  <td colspan="10" class="text-center">
                    <strong>No items added.</strong>
                  </td>
                </tr>
                </tbody>
              </table>
              <b-pagination
                  v-if="logs.length > 0"
                  class="pull-right mt-7"
                  @input="getAllCronLogs"
                  v-model="page"
                  :total-rows="total"
                  :per-page="perPage"
                  first-number
                  last-number
                  :disabled="loading"
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>

    </div>
    <log-detail ref="log-detail"></log-detail>
  </v-app>
</template>
<script>
import CronLogService from "@/services/cron-log/CronLogService";
import LogDetail from "@/view/pages/view/cron-job/LogDetail.vue";
const cronLog=new CronLogService();
export default {
  components:{
    LogDetail
  },
  data(){
    return{
      logs:[],
      total: null,
      perPage: null,
      page: null,
      loading: false,
      search:{
        status:'',
        run_at:null,
      },
      search_statuses: [
        {name: 'All', value:null},
        {name: 'Success', value: 'success'},
        {name: 'Error', value: 'error'},
      ],
      date_menu:false,
    }
  },
  methods:{
    searchCronLog(){
      this.page=1;
      this.getAllCronLogs();
    },
    viewCronLogDetail(errorLogId){
      this.$refs['log-detail'].viewCronLogDetail(errorLogId);
    },
    getAllCronLogs(){
      this.loading=true;
      cronLog
          .paginate(this.search,this.page)
          .then(response => {
           this.logs=response.data.data;
            this.page = response.data.meta.current_page;
            this.total = response.data.meta.total;
            this.perPage = response.data.meta.per_page;
          })
          .catch(error => {
            this.$snotify.error("Oops something went wrong!!");
          })
          .finally((err) => {
            this.loading=false;
          });
    }
  },
  mounted() {
    this.getAllCronLogs();
  }
}
</script>